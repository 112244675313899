// ==========================================================================
// Generic / Buttons
// ==========================================================================

//
// 1. Allow us to style box model properties.
// 2. Fixes odd inner spacing in IE7.
// 3. Reset/normalize some styles.
// 4. Line different sized buttons up a little nicer.
// 5. Make buttons inherit font styles (often necessary when styling `input`s as buttons).
// 6. Force all button-styled elements to appear clickable.
//

@mixin buttonBgBaseStyles {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: currentColor;
  display: block;
}

@mixin buttonTextBaseStyles {
  padding: 1rem 2rem;
  position: relative;
  display: block;
  z-index:1;
}

@mixin resetButton {
    outline: 0;
    display: inline-block; /* [1] */
    overflow: visible; /* [2] */
    margin: 0; /* [3] */
    padding: 0;
    border: 0;
    background: none transparent;
    color: inherit;
    vertical-align: middle; /* [4] */
    text-align: center; /* [3] */
    text-decoration: none;
    text-transform: none;
    font: inherit; /* [5] */
    line-height: normal;
    cursor: pointer; /* [6] */
    user-select: none;
}


button {
    @include resetButton;
}

.o-button {
    // State selectors
    $this: nth(&,1);
    $hovered: '#{$this}:hover &';
    $black: '#{$this}.-black &';
    $blackHovered: '#{$this}.-black:hover &';
    $white: '#{$this}.-white &';
    $whiteHovered: '#{$this}.-white:hover &';
    $minimal: '#{$this}.-minimal &';
    $secondary: '#{$this}.-secondary &';
    $secondaryHovered: '#{$this}.-secondary:hover &';

    @include resetButton;

    @include u-hocus {
        text-decoration: none;
    }

    overflow: hidden;
    display: inline-block;
    position: relative;
    letter-spacing: .05em;
    line-height: 1;
    font-weight: bold;
    text-transform: uppercase;
    @include responsive-resize(font-size, $l-tiny, $l-huge, $font-size-heading-min, $font-size-heading-max);
    transition: opacity .4s $easing;

    &.-white {
        color: white;
    }

    &.-black {
        color: black;
    }

    &:after {
        content: '';
        border: 2px solid currentColor;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    &:disabled {
        opacity: .2;
        pointer-events: none;
    }

    // Button text
    &_text {
        @include buttonTextBaseStyles;
        transition: color .2s .1s;

        #{$black} {
            color: $c-background;
        }

        #{$white} {
            color: $c-background;
        }

        #{$secondary} {
            color: $c-black;
        }

        #{$minimal} {
            padding: rem(5px) rem(15px);
        }

        // Hovered states
        #{$hovered} {
            transition-delay: 0s;
        }

        #{$blackHovered} {
            color: $c-black;
        }

        #{$whiteHovered} {
            color: $c-black;
        }

        #{$secondaryHovered} {
            color: $c-black;
        }

    }

    // Button background
    &_text:before {
        @include buttonBgBaseStyles;

        #{$hovered} {
            transition-delay: .25s;
        }

        #{$black} {
            color: $c-black;
        }

        #{$white} {
            color: transparent;
        }

        #{$secondary} {
            color: transparent;
        }
    }

    // Button hover background
    &_text:after {
        @include buttonBgBaseStyles;
        transform: scaleX(0);
        transform-origin: center right;
        transition: transform .25s ease-in;

        #{$hovered} {
            transform: scaleX(1);
            transition-timing-function: ease-out;
            transform-origin: center left;
        }

        #{$black} {
            color: $c-background;
        }

        #{$white} {
            color: $c-background;
        }

        #{$secondary} {
            color: transparent;
        }
    }

    & + & {
        margin-left: $padding/2;
    }

    &.is-showing-off {
        animation: isShowingOff .1s alternate infinite ease-in-out;
    }
}

@keyframes isShowingOff {
    from {
        transform: translateX(2px);
    }
    to {
        transform: translateX(-2px);
    }
}
