$order-border: rgba(0, 0, 0, 1);

.order {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;

    ul {
        padding-left: 0;
        margin-bottom: 0;
    }
}

.order_item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @include media('>=small') {
        @include space-horizontally(2rem);
        flex-wrap: nowrap;
    }

    & + & {
        padding-top: ($padding / 2);
        margin-top: ($padding / 2);
        border-top: solid 1px $order-border;
    }
}

.cart_item_preview_img {
    width: 100%;
    height: auto;
}

.order_item_image {
    a {
        display: block;
    }
    margin-bottom: 1rem;
    width: 100%;

    @include media('>=small') {
        margin-bottom: 0;
        width: 20%;
    }
}

.order_item_details {
    max-width: 80%;
    flex-grow: 1;

    @include media('>=small') {
        max-width: 60%;

    }

    .t-heading-sub {
        margin-bottom: 0;
    }
}

.order_item_price {
    text-align: right;
    width: 20%;
}

.order_totals {
    margin-top: $padding;
    border-bottom: solid 1px $order-border;

}

.order_totals_row {
    display: flex;
    justify-content: space-between;
    padding-top: 1rem;
    border-top: solid 1px $order-border;

    &:not(:first-child) {
        margin-top: 1rem;
        padding-top: 1rem;
    }
}

.order_group {
    margin-top: $padding;
}
