// ==========================================================================
// Settings / Config / Colors
// ==========================================================================

// Palette
// =============================================================================

$c-white      : #FFF;
$c-light-gray : #FAFAFA;
$c-grey       : #555;
$c-black      : #111;


// Theme
// =============================================================================

// Brand
$c-brand   : #000;
$c-brand-2 : #EEE;

// Text & Background
$c-text       : #111;
$c-background : #F7F7F7;
$c-canvas     : #111;

// Primary links
$c-link          : currentColor;
$c-link-hover    : mix(#FFF, $c-black, 10%);
$c-link-active   : mix(#FFF, $c-black, 20%);

// Secondary links
$c-link-2        : #000;
$c-link-2-hover  : #333;
$c-link-2-active : $c-grey;

// Selection
$c-selection-text-color: $c-white;
$c-selection-background-color: $c-black;

// Misc
$c-heading : $c-brand;
$c-border  : $c-grey;
$c-tap     : transparent;

// Page themes
$c-bg     : #111;
$c-bg-page: #FFF;
$c-bg-home: #ffe575;
$c-bg-cart: #ffe575;

$c-error: #e75f5f;

// Social Colors
// =============================================================================
$c-facebook:  #3B5998;
$c-instagram: #E1306C;
$c-youtube:   #CD201F;
$c-twitter:   #1DA1F2;

// Form Colors
// =============================================================================
$c-form-border:  #000;
$c-form-background:  #FFF;

$c-modal-background: rgba(0,0,0,.95);
