.content {
	margin: 0 auto;

    @include media('>=large') {
        max-width: 60%;
    }

	> *:last-child {
		margin-bottom: 0;
	}

	&.content--centered {
		text-align: center;
	}
}

.content_copy {

	> *:first-child {
		margin-top: 0;
	}

	margin: 1rem 0;

	h2 {
		margin: 3rem 0 1rem 0;
	}

	h3 {
		margin: 2rem 0 1rem 0;
	}

	a {
        text-decoration: none;
        border-bottom: 1px solid $c-black;
	}

	em {
		font-style: italic;
	}
}

$verticalSpacing: 2.5rem;

.content_heading,
.content_cta {
	margin-top: $verticalSpacing;
}

.content_part {
	&:not(:last-child) {
		margin-bottom: $verticalSpacing;
	}
}
