// ==========================================================================
// Objects / Image
// ==========================================================================

.o-image {
    &_text {
        margin-top: 10px;
    }
}



@mixin image {
    object-fit: cover;
    font-family: 'object-fit: cover;'; // for https://github.com/bfred-it/object-fit-images
    position: absolute;
    min-width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%,-50%,0);
}

.o-image-lazy {

    & {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        overflow: hidden;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0;
        z-index: 0;
        // zero ftw!
    }

    &__img {
        transition: opacity 1s;

        & {
            @include image;
            z-index: 1;
        }

        &.-is-hidden {
            opacity: 0;
        }
    }
}
