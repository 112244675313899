// ==========================================================================
// Main
// ==========================================================================

// Vendor
// ==========================================================================
@import "include-media/dist/include-media";

// Settings
// ==========================================================================
@import "settings/colors";
@import "settings/config";
@import "settings/selectors";

// ==========================================================================
// Tools
// ==========================================================================
@import "tools/functions";
@import "tools/mixins";
@import "tools/layout";
@import "tools/ratio";
@import "tools/widths";
@import "tools/type";

// Generic
// ==========================================================================
@import "generic/normalize";
@import "generic/generic";
@import "generic/media";
@import "generic/form";
@import "generic/button";
@import "generic/animation";

// Base
// ==========================================================================
@import "base/page";

// Objects
// ==========================================================================
@import "objects/layout";
@import "objects/hamburger";
@import "objects/icon";

@import "objects/logo";
@import "objects/cartbutton";
@import "objects/shopbutton";
@import "objects/social";
@import "objects/image";
@import "objects/price";

// Components
// ==========================================================================
@import "components/page";
@import "components/header";
@import "components/nav";
@import "components/gallery";
@import "components/attributes";
@import "components/slider";
@import "components/emailSubscribe";
@import "components/emailSubscribePage";

// Utilities
// ==========================================================================
@import "utilities/helpers";
@import "utilities/widths";
@import "utilities/other";
@import "utilities/type";

// Development
// ==========================================================================
@import "tools/debug";


// Styles from Jig
// ==========================================================================
$grid-gutter: 1rem !default;
$grid-legacy-support: false !default;

@mixin grid-wrap( $gutter:$grid-gutter ) {
    margin-left: -$gutter;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @if $grid-legacy-support {
        letter-spacing: -0.31em;
    }
}

@mixin grid-item( $proportion:1, $gutter:$grid-gutter ) {
    width: $proportion * 100%;
    padding-left: $gutter;
    //overflow: hidden;

    @if $grid-legacy-support {
        display: inline-block;
        letter-spacing: normal;
        vertical-align: top;
    }
}

@mixin layout( $size ) {

    @media ( min-width: $size ) {
        @content;
    }

}

$layouts: (
    s: 			500px,
    m: 			700px,
    l: 			1000px,
    xl: 		1200px,
    xxl: 		1400px
);

$navHeight: 5rem;
$stdPadding: 2rem;

@import "original/attributes.scss";
@import "original/cart.scss";
@import "original/checkout.scss";
@import "original/content.scss";
@import "original/errors.scss";
@import "original/form.scss";
@import "original/main.scss";
@import "original/notices.scss";
@import "original/notification.scss";
@import "original/order.scss";
@import "original/panel.scss";
@import "original/payment.scss";
@import "original/productOverview.scss";
