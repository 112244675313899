// ==========================================================================
// Objects / Cartbutton
// ==========================================================================
.o-cartbutton {
    @include base;

    $burgerpadding: 25px;
    padding: $burgerpadding;
    margin-left: -$burgerpadding;
    margin-top: -$burgerpadding;
    margin-right: -$burgerpadding;
    margin-bottom: -$burgerpadding;
    display: block;
    transition: color .25s $easing;

    @include baseHover {
        color: $c-white;
    }

    &_wrap {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        &:after {
            @include headerIconHover;
        }
    }

    &_icon {
        stroke: currentColor;
        stroke-width: 1.7;
        @include size(32px);
        display: inline-block;
    }

    &_count {
        text-align: center;
        padding: 5px;
        font-weight: bold;
        display: inline-block;
        font-size: .6rem;
        margin-top: 5px;
        @include position(absolute, 50% null null 50%);
        transform: translate(-50%, -50%);
    }
}
