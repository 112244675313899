$c-checkout-border: rgba(0,0,0,1);
$c-checkout-border-width: 1px;

.checkout_wrap {
    // Reset uls
    ul {
        list-style: none;
        padding-left: 0;
    }
    @media( min-width: map-get( $layouts, l)) {
        overflow: hidden;
        margin-left: -3rem;
    }

    a {
        border-bottom:1px solid $c-black;
    }
}

.checkout_main {
    @media( min-width: map-get( $layouts, l)) {
        width: 2/3 * 100%;
        float: left;
        padding-left: 3rem;
    }
}

.checkout_sidebar {
    padding-top: 2rem;
    @media( min-width: map-get( $layouts, l)) {
        width: 1/3 * 100%;
        float: left;
        padding-left: 3rem;
        padding-top: 0;
    }
}

.checkout_section {
    &:not(:last-child) {
        margin-bottom: 5rem;
    }

    &.is-hidden {
        display: none;
    }

    &_header {
        padding-bottom: 0rem;
    }

    // &_body {
    //     border-top: solid $c-checkout-border-width $c-checkout-border;
    // }
}

.checkout_differentShip {
    margin-top: 2rem;
}

.checkout_orderRow {
    border-bottom: solid $c-checkout-border-width $c-checkout-border;
    padding: 1rem 0;
    overflow: hidden;

    &.is-loading {
        opacity: 0.5;
    }

    &_left {
        float: left;
    }

    &_right {
        float: right;
    }
}

.checkout_coupon {
	border-bottom: solid $c-checkout-border-width $c-checkout-border;
    position: relative;

    &_field {
        width: 100%;
        height: 3rem;
        font: inherit;
        background-color: transparent !important;
        border: 0 !important;
        color: $c-black;
        border: none;
        border-radius: 0;
        padding: 0 4.5rem 0 .5rem;
        outline: none;
    }

    &_button {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    &_feedback {
        background-color: $c-error;
        padding: 0.5rem;

        &.is-hidden {
            display: none;
        }
    }
}

.checkout_shippingDescription {
    color: $c-white;
    margin-top: 1rem;
}

.checkout_pay {
    margin-top: 2rem;
}

.checkout_removeCoupon {
    color: $c-error;
}

.checkout_paymentBox {
    padding: 2rem;
    border: solid 2px $c-checkout-border;
    background-color: $c-white;
}

.payment_fields input[type="checkbox"] {
    -webkit-appearance: checkbox !important;
    display: inline-block;
}
