// ==========================================================================
// Tools / Mixins
// ==========================================================================

//
// Set the color of the highlight that appears over a link while it's being tapped.
//
// By default, the highlight is suppressed.
//
// @param  {Color} $value [rgba(0, 0, 0, 0)] - The value of the highlight.
// @output `-webkit-tap-highlight-color`
//
@mixin tap-highlight-color($value: rgba(0, 0, 0, 0)) {
    -webkit-tap-highlight-color: $value;
}

//
// Set whether or not touch devices use momentum-based scrolling for the given element.
//
// By default, applies momentum-based scrolling for the current element.
//
// @param  {String} $value [rgba(0, 0, 0, 0)] - The type of scrolling.
// @output `-webkit-overflow-scrolling`
//
@mixin overflow-scrolling($value: touch) {
    -webkit-overflow-scrolling: $value;
}

//
// Micro clearfix rules for containing floats.
//
// @link   http://www.cssmojo.com/the-very-latest-clearfix-reloaded/
// @param  {List} $supports The type of clearfix to generate.
// @output Injects `:::after` pseudo-element.
//
@mixin u-clearfix($supports...) {
    &::after {
        display: if(list-contains($supports, table), table, block);
        clear: both;
        content: if(list-contains($supports, opera), " ", "");
    }
}

//
// Generate a font-size and baseline-compatible line-height.
//
// @link   https://github.com/inuitcss/inuitcss/c14029c/tools/_tools.font-size.scss
// @param  {Number}  $font-size           - The size of the font.
// @param  {Number}  $line-height [auto]  - The line box height.
// @param  {Boolean} $important   [false] - Whether the font-size is important.
// @output `font-size`, `line-height`
//
@mixin font-size($font-size, $line-height: auto, $important: false) {
    font-size: rem($font-size) $important;
    $important: important($important);

    @if ($line-height == "auto") {
        line-height: ceil($font-size / $line-height) * ($line-height / $font-size) $important;
    }
    @else {
        @if (type-of($line-height) == number or $line-height == "inherit" or $line-height == "normal") {
            line-height: $line-height $important;
        }
        @elseif ($line-height != "none" and $line-height != false) {
            @error "D’oh! `#{$line-height}` is not a valid value for `$line-height`.";
        }
    }
}

//
// Vertically-center the direct descendants of the current element.
//
// Centering is achieved by displaying children as inline-blocks. Any whitespace
// between elements is nullified by redefining the font size of the container
// and its children.
//
// @output `font-size`, `display`, `vertical-align`
//
@mixin o-vertical-center {
    font-size: 0;

    &::before {
        display: inline-block;
        height: 100%;
        content: "";
        vertical-align: middle;
    }

    > * {
        display: inline-block;
        vertical-align: middle;
        font-size: 1rem;
    }
}

//
// Generate `:hover` and `:focus` styles in one go.
//
// @link    https://github.com/inuitcss/inuitcss/blob/master/tools/_tools.mixins.scss
// @content Wrapped in `:focus` and `:hover` pseudo-classes.
// @output  Wraps the given content in `:focus` and `:hover` pseudo-classes.
//
@mixin u-hocus {
    &:focus,
    &:hover {
        @content;
    }
}

//
// Generate `:active` and `:focus` styles in one go.
//
// @see     {Mixin} u-hocus
// @content Wrapped in `:focus` and `:active` pseudo-classes.
// @output  Wraps the given content in `:focus` and `:hover` pseudo-classes.
//
@mixin u-actus {
    &:focus,
    &:active {
        @content;
    }
}

//
// Injects generic rules for disabling UL/OL/LI styles.
//
// @output `list-style`, `margin`, `padding`
//
@mixin u-list-reset {
    margin: 0;
    padding: 0;
    list-style: none;
}

//
// Prevent text from wrapping onto multiple lines for the current element.
//
// An ellipsis is appended to the end of the line.
//
// 1. Ensure that the node has a maximum width after which truncation can occur.
// 2. Fix for IE 8/9 if `word-wrap: break-word` is in effect on ancestor nodes.
//
// @param  {Number} $width [100%] - The maximum width of element.
// @output `max-width`, `word-wrap`, `white-space`, `overflow`, `text-overflow`
//
@mixin u-truncate($width: 100%) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;  // [2]
    @if $width {
        max-width: $width; // [1]
    }
}

//
// Applies accessible hiding to the current element.
//
// @param  {Boolean} $important [true] - Whether the visibility is important.
// @output Properties for removing the element from the document flow.
//
@mixin u-accessibly-hidden($important: true) {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    margin: 0;
    padding: 0;
    width: 1px;
    height: 1px;
    border: 0;
    $important: important($important);
}

//
// Allows an accessibly hidden element to be focusable via keyboard navigation.
//
// @content For styling the now visible element.
// @output  Injects `:focus`, `:active` pseudo-classes.
//
@mixin u-accessibly-focusable {
    @include u-actus {
        clip: auto;
        width: auto;
        height: auto;

        @content;
    }
}

//
// Hide the current element from all.
//
// The element will be hidden from screen readers and removed from the document flow.
//
// @link   http://juicystudio.com/article/screen-readers-display-none.php
// @param  {Boolean} $important [true] - Whether the visibility is important.
// @output `display`, `visibility`
//
@mixin u-hidden($important: true) {
    display: none   $important;
    visibility: hidden $important;
    $important: important($important);
}

//
// Show the current element for all.
//
// The element will be accessible from screen readers and visible in the document flow.
//
// @param  {String}  $display   [block] - The rendering box used for the element.
// @param  {Boolean} $important [true]  - Whether the visibility is important.
// @output `display`, `visibility`
//
@mixin u-shown($display: block, $important: true) {
    display: $display $important;
    visibility: visible  $important;
    $important: important($important);
}

//
// Expand a shorthand syntax
//
@function unpack($shorthand) {
    @if length($shorthand) == 1 {
        @return nth($shorthand, 1) nth($shorthand, 1) nth($shorthand, 1) nth($shorthand, 1);
    } @else if length($shorthand) == 2 {
        @return nth($shorthand, 1) nth($shorthand, 2) nth($shorthand, 1) nth($shorthand, 2);
    } @else if length($shorthand) == 3 {
        @return nth($shorthand, 1) nth($shorthand, 2) nth($shorthand, 3) nth($shorthand, 2);
    } @else {
        @return $shorthand;
    }
}


//
// Set a position and coordinates with shorthand syntax
//
@mixin position($position: relative, $coordinates: null null null null) {
    @if type-of($position) == list {
        $coordinates: $position;
        $position: relative;
    }

    $coordinates: unpack($coordinates);

    $offsets: (
    top:    nth($coordinates, 1),
    right:  nth($coordinates, 2),
    bottom: nth($coordinates, 3),
    left:   nth($coordinates, 4)
    );

    position: $position;

    @each $offset, $value in $offsets {
        @if is-length($value) {
            #{$offset}: $value;
        }
    }
}

//
// Set a size with a shorthand syntax
//
@mixin size($value) {
    $width: nth($value, 1);
    $height: $width;

    @if length($value) > 1 {
        $height: nth($value, 2);
    }

    @if is-size($height) {
        height: $height;
    } @else {
        @warn "`#{$height}` is not a valid length for the `$height` parameter in the `size` mixin.";
    }

    @if is-size($width) {
        width: $width;
    } @else {
        @warn "`#{$width}` is not a valid length for the `$width` parameter in the `size` mixin.";
    }
}

//
// Space elements equally horizontally
//
@mixin space-horizontally($space: $unit) {
    > *:not(:first-child) {
        margin-left: #{$space};
    }
}

//
// Space elements equally vertically
//
@mixin space-vertically($space: $unit) {
    > *:not(:first-child) {
        margin-top: #{$space};
    }
}


// Interaction helpers
// https://codepen.io/reverson/pen/LLdzgR

@mixin base {
    $this: nth(&,1) !global;
    $hover: '#{$this}:hover &' !global;
    $focus: '#{$this}:focus &' !global;
    $hocus: '#{$this}:focus &, #{$this}:hover &' !global;
    $active: '#{$this}:active &' !global;
}

@mixin baseHover {
  @if '#{nth(&,1)}' == '#{$this}' {
    &:hover {
      @content;
    }
  } @else {
    #{$hover} {
      @content;
    }
  }
}

@mixin baseHocus {
  @if '#{nth(&,1)}' == '#{$this}' {
    &:hover, &:focus {
      @content;
    }
  } @else {
    #{$hocus} {
      @content;
    }
  }
}

@mixin baseFocus {
  @if '#{nth(&,1)}' == '#{$this}' {
    &:focus {
      @content;
    }
  } @else {
    #{$focus} {
      @content;
    }
  }
}

@mixin baseActive {
  @if '#{nth(&,1)}' == '#{$this}' {
    &:active {
      @content;
    }
  } @else {
    #{$active} {
      @content;
    }
  }
}


@mixin h {
    margin-top: 0;
    font-weight: normal;
    font-family: $font-heading;
    line-height: 1.3;
    @include responsive-resize(font-size, $l-tiny, $l-huge, $font-size-heading1-min, $font-size-heading1-max);
}

@mixin h2 {
    margin-top: 0;
    font-weight: normal;
    font-family: $font-heading;
    line-height: 1.3;
    @include responsive-resize(font-size, $l-tiny, $l-huge, $font-size-heading2-min, $font-size-heading2-max);
}

@mixin sh {
    margin-top: 0;
    font-size: 1rem;
    letter-spacing: .05em;
    font-weight: bold;
    text-transform: uppercase;
    @include responsive-resize(font-size, $l-tiny, $l-huge, $font-size-heading-min, $font-size-heading-max);
}

@mixin headerIconHover {
    content: '';
    background-color: $c-black;
    border-radius: 50%;
    @include position(absolute, 50% null null 50%);
    transform: translate(-50%, -50%) scale(.8);
    @include size(3.5rem);
    z-index: -1;
    opacity: 0;
    transition: transform .15s $easing,
    opacity .15s $easing;

    @include baseHover {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
}
