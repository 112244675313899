.errors {
    padding: 2rem;
    margin-bottom: 2rem;
    background-color: $c-error;
}

.errors_item {
    &:not(:first-child) {
        margin-top: 0.5rem;
    }
}
