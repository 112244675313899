//
// Nav
// Object
// ----------------------------------------------------------------

$nav-item: '.c-nav_item';

.c-nav {
    @include position(fixed, 0 0 0 0);
    visibility: hidden;
    transition: visibility 0s .8s;
    z-index: add-index(nav);
    overflow: hidden; // For logo

    #{$nav-is-open} & {
        visibility: visible;
        transition-delay: 0s;
    }

    &_background {
        cursor: pointer;
        @include position(fixed, 0 0 0 0);
    }

    &_footer {
        text-align: center;
        height: 15vh;
        @include position(absolute, null 0 0 0);
        color: $c-white;
        z-index: 3;
        transform: translateY(500px);
        transition: transform $speed-modal $easing;

        #{$nav-is-open} & {
            transform: translateY(0);
            transition-delay: .6s;
        }
    }

    &_footer,
    &_main,
    &_main:after {
        width: 100%;

        @include media('>=small') {
            width: 2/3 * 100%;
        }

        @include media('>=large') {
            width: 1/2 * 100%;
        }
    }

    &_main {
        height: 85vh;
        text-align: center;
        @include o-vertical-center;

        &:after {
            background-color: $c-modal-background;
            bottom: 0;
            content: "";
        }

        &:after {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 3;
            transform: scaleX(0);
            transition: transform $speed-modal $easing .2s;
            transform-origin: center left;
        }

        #{$nav-is-open} &:after {
            transform: scaleX(1);
            transition-delay: 0s;
        }
    }

    &_list {
        @include u-list-reset;
    }

    &_link {
        position: relative;
        display: block;
        color: $c-white;
        padding: rem(5px) 0;
        z-index: 4;
        transition: opacity .15s;
        @include h;

        &:hover,
        &:focus {
            color: $c-white;
            opacity:.5;
        }
    }

    &_label {
        display: inline-block;
        transform: translateX(-100vw);
        transition: transform $speed-modal $easing;

        $i: 5;
        @for $i from 1 through 5 {
            #{$nav-item}:nth-child(#{$i}) & {
                transition-delay: #{$i  * .05s};
            }

            #{$nav-is-open} #{$nav-item}:nth-child(#{$i}) & {
                transition-delay: #{$i * .05s};
            }
        }

        #{$nav-is-open} & {
            transform: translateX(0);
        }
    }

    &_social {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 4;
        padding-bottom: 3.4375rem;

        @include media('<medium') {
            width: 100%;
        }

        @include media('>=medium') {
            width: 50rem;
        }
    }
}
