$gallery-is-open: 'body.gallery-is-open';
$gallery-link-hover: '.c-gallery_link:hover';
$gallery-link-is-big: '.c-gallery_link.-big';
$gallery-link-is-small: '.c-gallery_link.-small';

.c-gallery {
    margin: 0 auto;

    // @include media('>=huge') {
        max-width: 50rem;
    // }

    &_wrap {
        padding-bottom: 105%;
        position: relative;
        margin-left: rem($unit/2);
        margin-top: rem($unit/2);
    }

    &_link {
        background-color: $c-white;
        display: block;
        transition: transform .6s $easing;
        overflow: hidden;
        @include position(absolute, 0 0 0 0);
        transition: opacity .2s;
        box-shadow: 0 0 0 1px darken($c-background, 2%);

        // Used on the homepage slider
        &.-bg-large {
            background-size: 275%;
            background-position: 50%;
        }

        &.-bg-cover {
            background-size: cover;
            background-position: 50%;
        }

        &.-bg-contain {
            background-size: contain;
            background-position: 50%;
            background-repeat: no-repeat;
        }

        @include media('>small') {
            & + & {
                margin-top: rem($unit/2);
            }

            &.-big {
                margin-bottom: rem(-$unit / 2);
            }
        }
    }

    &_content {
        display: block;
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
    }

    &_info {
        $info-padding: 3rem;

        color: $c-white;
        position: absolute;
        line-height: 1.2;
        @include size(100%);
        z-index: 2;
        text-align: center;
        padding: $info-padding;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: column;

        // Background
        &:before {
            content: "";
            position: absolute;
            opacity: 0;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transform-origin: bottom center;
            transition: transform .6s $easing, opacity .6s $easing .2s;
            background-color: rgba(0,0,0,.8);
        }

        #{$gallery-link-hover} &:before {
            opacity: 1;
            transition-delay: 0s;
        }

        &_title,
        &_type {
            display: block;
            transform: translateY(100%);
            opacity: 0;
            transition: transform .6s $easing, opacity .6s $easing;
            transform: scale(1.1);

            #{$gallery-link-hover} & {
                transform: translateY(0);
                opacity: 1;
            }
        }

        &_type {
            margin-top: ($unit / 6);
            transition-delay: 0s;

            #{$gallery-link-hover} & {
                transform: scale(1);
                transition-delay: .2s;
            }
        }

        &_title {
            transition-delay: .2s;
            font-weight: normal;
            font-family: $font-heading;
            line-height: 1.3;
            font-size: 1.5rem;

            @include media('>=large') {
                font-size: 1.2rem;

                .c-gallery_link.-big & {
                    @include h;
                }
            }

            #{$gallery-link-hover} & {
                transform: scale(1);
                transition-delay: 0s;
            }
        }
    }

    &_image {
        display: block;
        position: relative;
        text-align: center;

        img {
            width: 100%;
        }
    }
}
