// ==========================================================================
// Tools / Type - Responsive
// ==========================================================================

// This Responsive Typography is to show the simplier syntax
// https://codepen.io/jnowland/pen/GWgbMP

// Built of the back of the authors of:
// http://www.sassmeister.com/gist/7f22e44ace49b5124eec
// https://codepen.io/stowball/pen/JWoWWz?editors=0100

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

// Base Responsive resize function that the type one uses
@mixin responsive-resize($properties, $min-vw, $max-vw, $min-value, $max-value) {
  @each $property in $properties {
    #{$property}: $min-value;
  }

  @media (min-width: $min-vw) and (max-width: $max-vw) {
    @each $property in $properties {
      #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)});
    }
  }

  @media (min-width: $max-vw) {
    @each $property in $properties {
      #{$property}: $max-value;
    }
  }
}
