@keyframes loader-spin {
    from {
        transform: rotate( 0deg );
    }
    to {
        transform: rotate( 359deg );
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
