
.emailSubscribePage {

	&.-hide-heading-and-text {
		h1, h2 {
			display: none;
		}

		p {
			display: none;
		}
	}

	form {
		margin-top: 1rem;

		input {
			@extend %inputStyles;
		}

		button {
			@include resetButton;
			overflow: hidden;
			display: inline-block;
			position: relative;
			letter-spacing: .05em;
			line-height: 1;
			font-weight: bold;
			text-transform: uppercase;
			@include responsive-resize(font-size, $l-tiny, $l-huge, $font-size-heading-min, $font-size-heading-max);
			transition: opacity .4s $easing;
			color: $c-white;
			background-color: $c-black;
			padding: 1rem;
			width: 100%;
			margin-top: 2rem;
			border: 1px solid $c-black;

			&:hover {
				color: $c-black;
				background-color: $c-white;
			}
		}

		label {
			display: block;
			margin-bottom: 1rem;
		}

		// default CM markup
		& > div {
			@include space-vertically( 1rem );
		}

	}

}

.u-copy {

	&.-first .emailSubscribePage {
		h1, h2 {
			@include h;
		}
	}

	&:not(.-first) .emailSubscribePage {
		h1, h2 {
			@include sh;
		}
	}
	
}