.productOverview_container {
    width: 100%;
}

.productOverview_media {
    width: 100%;
    position: relative;
    background-color: $c-white;
    box-shadow: 0 0 0 1px darken($c-background, 2%);
}

@include media('>=large') {

    .productOverview {
        width: 50%;
        margin-left: auto;
    }

    .productOverview_media {
        margin-bottom: 0;
        @include grid-item(1/2, 0);
        @include position(fixed, 0 0 0 0);
        width: 50%;
        margin: 0;
        @include o-vertical-center;
    }

    .product_gallery {
        width: 100%;
    }
}
