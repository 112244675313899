// ==========================================================================
// Tools / Ratio Constraint
// ==========================================================================

//
// A tool to restrain a container to a unitary or fractional proportion.
//

$data-ratios: "1/2" "0.5" 50%,
              "11/20" "0.55" 55%,
              "3/5" "0.6" 60%,
              "13/20" "0.65" 65%,
              "7/10" "0.7" 70%,
              "3/4" "0.75" 75%,
              "4/5" "0.8" 80%,
              "17/20" "0.85" 85%,
              "9/10" "0.9" 90%,
              "19/20" "0.95" 95%,
              "1/1" "1" 100%,
              "21/20" "1.05" 105%,
              "11/10" "1.1" 110%,
              "23/20" "1.15" 115%,
              "6/5" "1.2" 120%,
              "5/4" "1.25" 125% !default;
$data-ratio-crops: "top" "bottom" "both" !default;

@mixin crop($crop) {
    @if $crop == "top" {
        bottom: 0;
    } @else if $crop == "bottom" {
        top: 0;
    } @else if $crop == "both" {
        top: 50%;
        transform: translateY(-50%);
    }
}

.u-ratio {
    position: relative;
    overflow: hidden;

    &::before {
        display: block;
        width: 100%;
        content: "";
    }

    @each $ratio in $data-ratios {
        $ratio-1: nth($ratio, 1);
        $ratio-2: nth($ratio, 2);
        &[data-ratio="#{$ratio-1}"]::before,
        &[data-ratio="#{$ratio-2}"]::before {
            padding-top: nth($ratio, 3);
        }
    }
}

.u-ratio_content_container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
}

.u-ratio_content {
    position: absolute;
    width: 100%;

    @each $crop in $data-ratio-crops {
        &[data-ratio-crop="#{$crop}"] {
            @include crop($crop);
        }
    }
}
