// ==========================================================================
// Base / Page
// ==========================================================================

//
// Simple page-level setup.
//
// 1. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
//    navigating between pages that do/do not have enough content to produce
//    scrollbars naturally.
// 2. Ensure the page always fills at least the entire height of the viewport.
//
html {
    overflow-y: scroll; /* [1] */
    min-height: 100%; /* [2] */
    color: $c-text;
    background: $c-canvas;
    @include responsive-resize(font-size, $l-tiny, $l-huge, $font-size-min, $font-size-max);
    font-family: $font-family;
    line-height: $line-height;

    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: $c-tap;
}

body {
    background-color: $c-background;
}

a {
    @include u-hocus {
        color: $c-link-hover;
    }

    color: $c-link;
}

::selection {
    background-color: $c-selection-background-color;
    color: $c-selection-text-color;
    text-shadow: none;
}
