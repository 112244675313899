//
// Icon
// Object
// ----------------------------------------------------------------

.o-icon {
    width: 1em;
    height: 1em;
    fill: currentColor;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 1;

    &.-inline {
        margin-top: -.18em;
    }

    &.-stroke {
        stroke: currentColor;
        fill: none;

        * {
            vector-effect: non-scaling-stroke;
        }
    }

    svg {
        width: 1em;
        height: 1em;
        display: block;
    }

    + a {
        margin-left: .35em;
    }
}
