// ==========================================================================
// Utilities / Other
// ==========================================================================

.u-nosel {
    user-select: none;
}

.u-hidden {
    display: none;
}


// For the alternating / split templates

.u-image-cover {
    height: 100vh;
    background-size: cover;
    background-position: 50%;
    background-color: darken($c-background, 5%);
    position: relative;
    // box-shadow: 0 -6rem 3rem -3rem $c-background;
}

.u-image-fixed {
    height: 100vh;
    background-size: cover;
    background-position: 50%;
    position: fixed;
    background-color: darken($c-background, 5%);
}

// .o-sticky {
//     @include media('>=medium') {
//         position:sticky;
//         top: 0px;
//     }
// }

.u-copy {
    background-color: $c-background;
    padding: ($padding * 1);

    @include media('>=medium') {
        padding: ($padding * 2);
    }

    &.-extra-pad-vert {
        padding-top: ($padding * 2);
        padding-bottom: ($padding * 2);

        @include media('>=medium') {
            padding-top: ($padding * 3);
            padding-bottom: ($padding * 3);
        }
    }

    &.-no-pad-top {
        padding-top: 0;
    }

    &.-full {
        @include media('>=medium') {
            min-height: 100vh;
        }
    }

    p:last-child {
        margin-bottom: 0;
    }
}
