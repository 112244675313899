

%inputStyles {
    width: 100%;
    height: 3rem;
    font: inherit;
    background-color: transparent;
    color: currentColor;
    border: 1px solid;
    border-radius: 0;
    padding: 0 1rem;
    outline: none;
}

.checkout_coupon_field {
    @extend %inputStyles;
}

.form_fields {
    overflow: hidden;

    label {
        display: block;
        margin-bottom: 1rem;

        abbr {
            display: none;
        }
    }

    .form-row {
        margin-top: 1rem;
        width: 100%;
    }

    .form-row--seperated {
        margin-top: 2rem;
    }

    select, .input-text {
        @extend %inputStyles;
    }

    .customSelect {
        width: 100%;
        height: 3rem;
        line-height: 3rem;
        font: inherit;
        background-color: transparent;
        color: currentColor;
        padding: 0 3rem 0 1rem;
        background-image: url(../images/chevron.svg);
        background-position: center right;
        background-size: 3rem auto;
        background-repeat: no-repeat;
        border: 1px solid;
    }

    .customSelectInner {
        vertical-align: middle;
        line-height: 3rem;
    }

    .hasCustomSelect {
        cursor: pointer;
    }
}



.form_radio {
    display: inline-block;
    height: 2rem;
    line-height: 1rem;

    label {
        cursor: pointer;
        height: 1.5rem;
        line-height: 1.5rem;

        &:before {
            content: "";
            display: inline-block;
            width: 1.3rem;
            height: 1.3rem;
            margin-right: 0.5rem;
            vertical-align: middle;
            border-radius: 50%;
            border: solid 2px $c-black;
        }
    }

    input:checked + label {
        &:before {
            background-color: $c-black;
            box-shadow: inset 0 0 0 2px $c-white;
        }
    }
}


.form_checkbox {
    overflow: hidden;

    label {
        cursor: pointer;
        height: 1.5rem;
        line-height: 1.5rem;

        &:before {
            content: "";
            display: inline-block;
            width: 1.3rem;
            height: 1.3rem;
            border: solid 2px $c-black;
            margin-right: 0.5rem;
            vertical-align: middle;
        }
    }

    input:checked + label {
        &:before {
            background-image: url(../images/tick.svg);
            background-size: 80%;
            background-repeat: no-repeat;
        background-position: 50%;
        }
    }
}

@media ( min-width: map-get( $layouts, s ) ) {

    .form_fields {

        .form-row-wide {
            float: left;
        }

        .form-row-first {
            width: 50%;
            padding-right: 0.5rem;
            float: left;
        }

        .form-row-last {
            width: 50%;
            padding-left: 0.5rem;
            float: right;
        }
    }
}
