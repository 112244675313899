// ==========================================================================
// Debug: Development helpers
// ==========================================================================

body.is-showing-media {
  &:before {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    background: #000;
    color: #FFF;
    padding: 0 3px;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    z-index: 10000000;
    border-radius: 0 0 2px 0;

    @include media('>=tiny') {
      content: 'tiny';
    }
    @include media('>=small') {
      content: 'small';
    }
    @include media('>=medium') {
      content: 'medium';
    }
    @include media('>=large') {
      content: 'large';
    }
    @include media('>=big') {
      content: 'big';
    }
    @include media('>=huge') {
      content: 'huge';
    }
  }
}

// Outline elements for easy block recognition
body.is-showing-outlines * { outline:1px solid red !important }

#grid {
    position: fixed;
    height: calc(100% - (#{$padding} * 2));
    width: calc(100% - (#{$padding} * 2));
    top: $padding;
    left: $padding;
    padding: $padding;
    z-index: 10000000;
    border: 1px solid #CCC;
    pointer-events: none;

    div {
        position: absolute;
        width: 0;
        top: 0;
        border-left: 1px solid #CCC;
        height: 100%;

        &:nth-of-type(1) {
            left: 10%;
        }
        &:nth-of-type(2) {
            left: 20%;
        }
        &:nth-of-type(3) {
            left: 30%;
        }
        &:nth-of-type(4) {
            left: 40%;
        }
        &:nth-of-type(5) {
            left: 50%;
        }
        &:nth-of-type(6) {
            left: 60%;
        }
        &:nth-of-type(7) {
            left: 70%;
        }
        &:nth-of-type(8) {
            left: 80%;
        }
        &:nth-of-type(9) {
            left: 90%;
        }
    }
}
