@import "slick-carousel/slick/slick.scss";

// Fixes for iOS11
.slick-track,
.slick-list {
    height: inherit;
}

.slick-slide > div {
    height: inherit;
}

// end fix

@mixin base {
    $this: nth(&,1) !global;
}

@mixin fullSlider {
  @if ('#{nth(&,1)}' == '#{$this}') {
    &.-full {@content;}
  } @else {
    #{$this}.-full & {@content;}
  }
}

@mixin halfSlider {
  @if ('#{nth(&,1)}' == '#{$this}') {
    &.-half {@content;}
  } @else {
    #{$this}.-half & {@content;}
  }
}

@mixin slideSingle {
    #{$this}_slide.-single & {@content;}
}

@mixin slideDouble {
    #{$this}_slide.-double & {@content;}
}

@mixin sliderIsActive {
    body.slider-is-active & {@content;}
}


.c-slider {
    @include base;

    z-index: 1;
    white-space: nowrap;
    position: relative;
    height: 100vh;

    @include halfSlider {
        width: 100%;
    }

    &_slide-wrap {
        font-size: 0;
    }

    &_item {
        position: relative;
        height: 100%;
        font-size: 1em;
        overflow: hidden; // Remove overhang on mobiles

        @include slideSingle {
            width: 100%;
            height: calc(100vh - #{$header-height});
            margin-top: #{$header-height};
            display: inline-block;

            @include media('>=large') {
                height: 100vh;
                margin-top: 0;
            }
        }

        @include slideDouble {
            display: block;
            height: 100vh;
            width: 100%;

            @include media('>=large') {
                width: 50%;
                height: 100vh;
                display: inline-block;
            }
        }
    }

    &_arrow {
        position: absolute;
        top: 50%;
        width: 6rem;
        height: 5rem;
        z-index: 1;
        color: $c-black;
        font-size: 2rem;
        cursor: pointer;
        visibility: hidden;
        display: none;

        .slider-is-active {
            display: block;
        }

        @include sliderIsActive {
            visibility: visible;
        }

        &.-next {
            right: 0;
            border-right-width: 0;
            transform: translate( 0, -50% );

            .o-icon {
                transform: translate(3px, -3px);
            }
        }

        &.-prev {
            left: 0;
            border-left-width: 0;
            transform: translate( 0, -50% );

            .o-icon {
                transform: translate(-3px, -3px);
            }
        }
    }
}
