
.notification {
  cursor: pointer;
  background-color: $c-modal-background;
  color: $c-white;
  padding: $padding / 2 ($padding);
  position: fixed;
  right: -1px;
  top: 6rem;
  transition: transform $speed-modal $easing;
  z-index: 999;
  transform: translateX(100%);
  transform-origin: top right;

  &.is-active {
    transform: translateX(0);
  }

  &.is-link {
    display: block;

    &:hover {
      background-color: $c-grey;
    }

    .notification_icon {
      color: $c-white;
    }
  }

  &_text {
    margin: 0;
  }
}

.notification_heading {
  margin: 0;
  white-space: nowrap;
}

.notification_icon {
    display: none;
}
