
.payment_options {
	overflow: hidden;
	margin-top: 2rem;
}

.payment_box {
	margin-top: 1rem;
	padding: 2rem;
	border: 1px solid;
	background-color: transparent;
	display: none;

	&.is-chosen {
		display: block;
	}

	.wc-payment-form {
		border: 0;
		padding: 0;
		margin: 0;
	}

	.woocommerce-error {
		margin-top: 1rem;
		color: $c-error;
	}
}

.payment_description {
	margin-top: 1rem;
	text-transform: none;
	font-weight: 200;
	opacity: 0.6;
}

.payment_icons {
    margin-top: 2rem;
    filter: grayscale(100%);

	img {
		display: inline-block;
		border: solid 1px $c-background;

		width: 4rem !important;
		height: auto !important;

		&:not(:first-child) {
			margin-left: 0.5rem;
		}
	}
}

@include layout( map-get($layouts, m) ) {

	.payment_options_item {
		float: left;

		&:not( :first-child ) {
			margin-left: 2rem;
		}
	}

}
