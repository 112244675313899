.attributes {
    margin: 0 auto;
    @include space-vertically(2.5rem);
}

.attributes_title {
    margin-bottom: 1rem;
}

.attributes_options {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.attributes_radio {
    position: relative;
    width: 100%;

    @include media('>=small') {
        width: 50%;
    }
}

.attributes_radio_label {
    width: 100%;
    padding: 0 1rem 0 3.5rem;
    text-align: left;
    cursor: pointer;
    position: relative;
    height: 3.5rem;
    line-height: 3.5rem;
    display: flex;
    align-items: center;
    overflow: hidden; // Avoids a bug in chrome
}

.attributes_radio_input {
    position: absolute;
    display: inherit;
    clip: rect(0, 0, 0, 0);
}

.attributes_radio_indicator {
    width: 2.75rem;
    height: 2.75rem;
    background-color: $c-grey;
    position: absolute;
    top: 0.5rem;
    left: 0rem;
    background-size: cover;
    background-repeat: repeat;
    background-position: 50%;
    pointer-events: none;
}

.attributes_radio_tick {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate( -50%, -50% ) scale(0);
    transition: transform 0.3s, opacity 0.3s;
    font-size: 1.25rem;
    color: $c-white;
    opacity: 0;
    pointer-events: none;
}

.attributes_radio_text {
    display: inline-block;
    vertical-align: middle;
    line-height: 1.2;
    pointer-events: none;
}

.attributes_radio_input:checked + .attributes_radio_label {
    color: $c-black;

    .attributes_radio_tick {
        opacity: 1;
        transform: translate( -50%, -50% ) scale(1);
    }
}

.attributes_radio_price {
    display: none !important;
}
