//
// Header
// Component
// ----------------------------------------------------------------

.c-header {
    @include position(fixed, 0 0 null 0);
    z-index: add-index(header);
    pointer-events: none;
    display: flex;
    justify-content: flex-start;

    > * {
        pointer-events: all;
    }

    &_hamburger,
    &_cart {
        padding: ($padding / 2) $padding;

        @include media('>=small') {
            padding: $padding;
        }
    }

    &_hamburger {
        #{$nav-is-open} & {
            color: $c-white;
        }
    }

    &_logo {
        text-align: center;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        flex-grow: 0;
        width: rem(80px);

        @include media('>=small') {
            width: rem(90px);
        }
    }

    &_additional {
        display: flex;
        margin-left: auto;
        flex-direction: row;
    }

    &_shop {
        @include media('<small') {
            display: none;
        }
        padding: $padding;
    }

    &_item + &_item {
        padding-left: 0;
    }
}
