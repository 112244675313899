.main {
	min-height: 100vh;
    position: relative;

    @include media('>=medium') {
        transition: filter 2s .5s;

        #{$nav-is-open} &,
            #{$cart-is-open} & {
            filter: grayscale(100%);
        }
    }
}
