
.t-heading {
    @include h;
}

.t-heading-sub {
    @include h2;
}

.t-heading-small,
.t-heading-mini {
    @include sh;
}

.t-super {
    vertical-align: top;
    display: inline-block;
}

.t-margin-bottom {
    margin-bottom: 0.5rem;
}
