//
// Attributes
// Component
// ----------------------------------------------------------------


.c-attributes {
    max-width: 25rem;
    margin: 0 auto;
    @include space-vertically(1.5rem);
}

.c-attributes_title {
    margin-bottom: 1rem;
}

.c-attributes_options {
    @include space-vertically(0.5rem);
}

.c-attributes_radio_label {
    width: 100%;
    display: block;
    padding: 0 4.5rem;
    box-shadow: 0 0 0 1px $c-grey;;
    height: 4.5rem;
    border-radius: 4.5rem;
    color: $c-grey;
    text-align: left;
    cursor: pointer;
    position: relative;
    line-height: 4.5rem;
}

.c-attributes_radio_input {
    display: none;
}

.c-attributes_radio_indicator {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-color: $c-black;
    position: absolute;
    top: 0.75rem;
    left: 0.75rem;
}

.c-attributes_radio_tick {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate( -50%, -50% ) scale(0);
    transition: transform 0.3s, opacity 0.3s;
    font-size: 1.5rem;
    color: $c-white;
    opacity: 0;
}

.c-attributes_radio_text {
    display: inline-block;
    vertical-align: middle;
    line-height: 1.2;
}

.c-attributes_radio_subtext {
    font-size: 0.6rem;
}

.c-attributes_radio_input:checked + .c-attributes_radio_label {
    color: $c-black;
    box-shadow: 0 0 0 1px $c-black;

    .c-attributes_radio_indicator {
        background-color: $c-brand-2;
    }

    .c-attributes_radio_tick {
        opacity: 1;
        transform: translate( -50%, -50% ) scale(1);
    }
}

.c-attributes_radio_price {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
}
