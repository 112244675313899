//
// Page
// Component
// ----------------------------------------------------------------

.c-page {
    transition: transform .2s $easing;
    min-height: 100%;
    padding: $padding;

    &--is-scrolling {
        filter: url("#blur");
        transform: translateZ(0);
    }

    &__content {
        position: relative;
        padding-top: #{$padding*2};
        padding-bottom: #{$padding*2};
    }
}
