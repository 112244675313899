//
// Hamburger
// Object
// ----------------------------------------------------------------

.o-hamburger {
    @include base;

    @include size(35px 36px);
    display: inline-block;
    position: relative;
    padding: 0;
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;
    transition: color 0s $easing;

    @include baseHover {
        color: $c-white;
    }

    // Background
    &_inner {
        display: block;
        position: relative;

        &:after {
            @include headerIconHover;
        }
    }

    &:before,
    &:after {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 2px;
        transform-origin: 50% 50%;
        transition: transform 0s $easing, background 0s $easing;
        content: '';
        pointer-events: none;
    }

    &:before,
    &:after,
    &_title {
        background-color: $c-black;
    }

    &:hover:before,
    &:hover:after,
    &:hover &_title {
        background-color: $c-white;
    }

    #{$nav-is-open} &:before,
    #{$nav-is-open} &:after {
        transition: transform $speed $easing 0s, background $speed $easing .05s;
    }

    &_title {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 2px;
        transition: opacity $speed ease;
        text-indent: 200%;
        overflow: hidden;

        #{$nav-is-open} & {
            opacity: 0;
        }
    }

    &:before {
        transform: translate3d(0, -6px, 0);
    }

    #{$nav-is-open} &:before {
        transform: rotate3d(0, 0, 1, 45deg);
        background-color: $c-white;
    }

    &:after {
        transform: translate3d(0, 6px, 0);
    }

    #{$nav-is-open} &:after {
        transform: rotate3d(0, 0, 1, -45deg);
        background-color: $c-white;
    }
}
