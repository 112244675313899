//
// Logo
// Object
// ----------------------------------------------------------------

.o-logo {
    $p: nth(&, 1);

    display: block;
    padding: ($padding / 2) ($padding / 2) 0 ($padding / 2);
    transition: opacity .25s .25s;

    #{$cart-is-open} &,
    #{$nav-is-open} & {
        transition-delay: 0s;
        opacity: 0;
    }
}
