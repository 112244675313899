// ==========================================================================
// Generic / Media
// ==========================================================================

//
// 1. Setting `vertical-align` removes the whitespace that appears under `img`
//    elements when they are dropped into a page as-is. Safer alternative to
//    using `display: block;`.
//
iframe,
img,
svg {
    vertical-align: middle; /* [1] */
}

//
// 2. Fluid media for responsive purposes.
//
img,
svg {
    max-width: 100%; /* [2] */
    height: auto;

    //
    // 4. If a `width` and/or `height` attribute have been explicitly defined, let’s
    //    not make the image fluid.
    //
    &[width], /* [4] */
    &[height] {
        /* [4] */
        max-width: none;
    }
}

//
// 4. Offset `alt` text from surrounding copy.
//
img {
    font-style: italic; /* [4] */
}

//
// 5. SVG elements should fallback to their surrounding text color.
//
svg {
    fill: currentColor; /* [5] */
}
