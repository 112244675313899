// ==========================================================================
// Objects / Shopbutton
// ==========================================================================
.o-shopbutton {
    @include base;

    $burgerpadding: 25px;
    padding: $burgerpadding;
    margin-left: -$burgerpadding;
    margin-top: -$burgerpadding;
    margin-right: -$burgerpadding;
    margin-bottom: -$burgerpadding;
    position: relative;
    display: block;
    transition: color .25s $easing;

    @include baseHover {
        color: $c-white;
    }

    &_wrap {
        position: relative;
    }

    &_icon {
        stroke: currentColor;
        stroke-width: 1.7;
        @include size(30px);
        display: block;
    }

    // Background
    &_inner {
        position:relative;

        &:after {
            @include headerIconHover;
        }
    }
}
