
%inputStyles {
    width: 100%;
    height: 3rem;
    font: inherit;
    background-color: transparent;
    color: currentColor;
    border: 1px solid;
    border-radius: 0;
    padding: 0 1rem;
    outline: none;
}

.emailSubscribe {
	position: fixed;
	left: 50%;
	transform: translateX(-50%);
	bottom: 2rem;
	max-width: 25rem;
	width: 100%;
	margin: 0 auto;
	padding: 2rem;
	background-color: $c-black;
	color: $c-white;
	z-index: 999;
	transition: transform 0.3s, opacity 0.3s, bottom 0s;

	&[aria-hidden="true"] {
		bottom: -200%;
		transform: translate( -50%, 10% );
		opacity: 0;
		transition: transfrom, 0.3s, opacity 0.3s, bottom 0s 0.3s;
	}

	form {

		input {
			@extend %inputStyles;
		}

		button {
			@include resetButton;
			overflow: hidden;
			display: inline-block;
			position: relative;
			letter-spacing: .05em;
			line-height: 1;
			font-weight: bold;
			text-transform: uppercase;
			@include responsive-resize(font-size, $l-tiny, $l-huge, $font-size-heading-min, $font-size-heading-max);
			transition: opacity .4s $easing;
			color: $c-black;
			background-color: $c-white;
			padding: 1rem;
			width: 100%;
			margin-top: 2rem;
			border: 1px solid $c-black;

			&:hover {
				color: $c-white;
				background-color: $c-black;
				border-color: $c-white;
			}
		}

		label {
			display: block;
			margin-bottom: 1rem;
		}

		// default CM markup
		& > div {
			@include space-vertically( 1rem );
		}

	}

	h2 {
		margin-top: 0;
	}

}

.emailSubscribe_close {
	background-color: $c-black;
	color: $c-white;
	width: 3.5rem;
	height: 3.5rem;
	position: absolute;
	top: -1rem;
	right: -1rem;
	border-radius: 50%;
	border: 2px solid $c-white;
	
	&:before, &:after {
		display: block;
		position: absolute;
		content: '';
		width: 2rem;
		height: 2px;
		background-color: currentColor;
		top: 50%;
		left: 0.75rem;
		margin-top: -1px;
	}
	
	&:before {
		transform: rotate(-45deg);
	}
	
	&:after {
		transform: rotate(45deg);
	}
	
	&:hover {
		background-color: $c-white;
		color: $c-black;
		border-color: $c-black;
	}
	
}