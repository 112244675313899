// ==========================================================================
// Objects / SocialMenu
// ==========================================================================
//

.c-social {

    &_heading {
        display: inline-block;
        font-size: $font-size;
        margin-bottom: ($unit / 6);
    }

    &_list {
        font-size: 0;
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &_item {
        display: inline-block;
    }

    &_link {
        position: relative;
        display: inline-block;
        padding: $unit / 3;

        &:focus,
        &:hover {
            color: $c-white;
        }
    }

    &_link:before {
        display: inline-block;
        height: 100%;
        content: "";
        vertical-align: middle
    }

    &_link .o-icon {
        display: inline-block;
        font-size: 1.25rem;
        vertical-align: middle;
    }

}
