// ==========================================================================
// Settings / Config
// ==========================================================================

// Context
// =============================================================================
// The current stylesheet context. Available values: frontend, editor.
$context: frontend  !default;

// Path is relative to the stylesheets directory.
$assets-path: "../"  !default;

// Typefaces
// =============================================================================
$font-sans-serif: "sofia-pro", sans-serif;
$font-serif: "bookman-jf-pro";


// Typography
// =============================================================================

// Base
$font-family: $font-sans-serif;
$font-heading: $font-serif;

$color:       #222222;

// Normal
$font-size : 16px;
$line-height: 24px / $font-size;
$font-size-min: 14px;
$font-size-max: 18px;
$font-size-body: $font-size;

// Font headings
$line-height-h: $line-height;
$font-size-heading-min: 14px;
$font-size-heading-max: 16px;
$font-size-heading1-min: 30px;
$font-size-heading1-max: 40px;
$font-size-heading2-min: 20px;
$font-size-heading2-max: 25px;

// Heights
$header-height: 100px;

// Weights
$light:  300;
$normal: 400;
$medium: 500;
$bold:   700;

// Transitions
// =============================================================================
$speed:  0.3s;
$speed-modal: (0.3s * 2);
$easing: cubic-bezier(.4,0,0,1);

// Spacing Units
// =============================================================================
$unit:       60px;
$unit-small: 30px;

// Container
// ==========================================================================
$container-width: 2000px;
$padding:         2.5rem;

// Breakpoints
// =============================================================================
$l-tiny:   350px; // Midway through small phone devices
$l-small:  600px; // Start of tablets portrait
$l-medium: 900px; // Start of tablets landscape
$l-large:  1200px; // Start of desktop
$l-big:    1500px;
$l-huge:   1800px;

// Usage: @include media('>=small') {}
$breakpoints: (
    tiny:   $l-tiny,
    small:  $l-small,
    medium: $l-medium,
    large:  $l-large,
    big:    $l-big,
    huge:   $l-huge
);

// Indexes
// =============================================================================
// Usage: z-index: add-index(overlay);
$z-index: (
    logo: 1,
    header: 10,
    nav: 9,
    overlay: 2,
    backtotop: 1,
    // Add your index
);


