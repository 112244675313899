
.notices {
	background-color: $c-black;
	color: $c-white;
	padding: 2rem 2rem 3rem 7rem;
	position: relative;
	margin-bottom: 5rem;
}

.notices_icon {
	color: currentColor;
	position: absolute;
	top: 2rem;
	left: 2rem;
	font-size: 4rem;
}

.notices_heading {
	margin-bottom: 1rem;
}

.notices_item {
	line-height: 1.4;

	&:not(:first-child) {
		margin-top: 0.5rem;
	}
}
