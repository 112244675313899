.panel_image {
	max-width: 50rem;
}

.panel_content {
	width: 100%;
	margin: 0 auto;
}

.panel_icon {
	font-size: 5rem;
	margin-bottom: 1rem;
}

.panel_heading {
	margin-bottom: 1rem;
}
