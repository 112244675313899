$cart-delay: .15s;
$c-cart-border: rgba(255,255,255,.2);

.cart {
    @include position(fixed, 0 0 null null);

    height: 100%;
    visibility: hidden;
    transition: visibility 0s ($cart-delay * 4.5);
    z-index: 300;
    color: $c-white;
    width: 100%;

    @include media('>=small') {
        width: 2/3 * 100%;
    }

    @include media('>=large') {
        width: 1/2 * 100%;
    }

    #{$cart-is-open} & {
        visibility: visible;
        transition-delay: 0s;
    }

    // Background
    &:after {
        content: '';
        cursor: pointer;
        @include position(absolute, 0 0 0 0);
        display: block;
        background-color: rgba(0,0,0,.95);
        transition: transform $speed-modal $easing $cart-delay;
        transform: scaleX(0);
        transform-origin: center right;
        z-index: -1;
    }

    #{$cart-is-open} &:after {
        transition-delay: 0s;
        transform: scaleX(1);
    }

    &_wrap {
        transition: transform 0s;
        height: inherit;
        transform: translateX(100vw);
        transition: transform $speed-modal $easing;
        transform-origin: center right;

        #{$cart-is-open} & {
            transition-delay: $cart-delay;
            transform: translateX(0);
        }
    }
}

.cart_header {
    position: absolute;
    top: 0;
    left: $padding;
    right: $padding;
    height: $navHeight;
    z-index: 20;
}

.cart_title {
    height: $navHeight;
    line-height: $navHeight;
}

.cart_close {
    float: right;
    height: $navHeight;
    line-height: $navHeight;
}

.cart_contents {
    transition: opacity 0.2s;
    overflow: auto;
    max-height: 100%;
    padding: $padding $padding 3rem $padding;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(100% - #{$navHeight});
    display: flex;
    flex-direction: column;
}

.cart_push {
    transition: transform 0.3s;

    .is-cart-open & {
        transform: translateX(-50%);
    }
}

.cart_empty {
  display: table;
  width: 100%;
  height: 100%;
  animation: fadeIn 2s backwards;
  position: relative;

  &_inner {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      padding-bottom: 8rem;

  }

  &_contained {
      max-width: 20rem;
      margin: 0 auto;
  }

  &_heading {
      margin-bottom: 2rem;
  }

}

.cart_items {
  flex-grow: 1;
}

.cart_details {
  border-top: solid 1px $c-cart-border;
}

.cart_row {
  overflow: hidden;
  position: relative;
  padding: 1rem 0;
  display: flex;

  &:not(:last-child) {
    border-bottom: solid 1px $c-cart-border;
  }
}

.cart_row--extraPad {
  padding: 2rem 0;
}

.cart_row {

    &_left {
        width: 50%;
        padding-right: 1rem;
    }

    &_right {
        margin-left: auto;
        text-align: right;
        width: 50%;
    }

    &_extraInfo {
        text-transform: uppercase;
        margin-top: 1rem;
        clear: both;
        // float: left;
        opacity: 0.5;
    }
}

.cart_item {
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-wrap: wrap;

    @include media('>=small') {
        flex-wrap: nowrap;
    }

    &_preview {
        display: block;
        width: 100%;
        margin-bottom: ($padding / 2);


        @include media('>=small') {
            width: rem(150px);
            margin-right: ($padding / 2);
            margin-bottom: 0;
        }

        &_img {
            width: 100%;
            height: auto;
        }
    }


    &_details {
        width: 100%;
    }

    &_header {
        display: flex;
        position: relative;

        .t-heading-sub {
            margin-bottom: 0;
        }

        a:focus,
        a:hover {
            color: $c-white;
        }
    }

    &_extras {
        margin-top: 1rem;
        overflow: hidden;
        display: flex;
    }

    &_remove {
        width: 10%;
        margin-left: auto;
        margin-top: -45px;
    }

    &_price {
        margin-left: auto;
        text-align: right;
    }
}


.cart_qty_control {
    color: $c-white;
    display: inline-block;
    line-height: 2rem;
    text-align: center;
    padding: 0 10px;
    line-height: 1;
    vertical-align: inherit;

  &:hover, &:focus {
    color: $c-white;
  }
}

.cart_qty_count {
  display: inline-block;
}

.cart_cta {
  text-align: right;
  margin-left: auto;
}

.cart.is-processing {

    .cart_contents {
        opacity: 0.3;

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

.cart_inpage {
    .o-button {
        color: black;

        .o-button_text {
            color: black;
        }
    }
}
