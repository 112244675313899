//
// Price
// Objects
// ----------------------------------------------------------------

.o-price {

    &_text {
        @include sh;
    }

    &_sale {}
}
